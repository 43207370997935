import React, { useEffect, useState } from 'react'
import Cup from '../images/sprite/cup.gif';
import CricketMatches from './CricketMatches';
import SearchBar from './SearchBar';
import SoccerMatches from './SoccerMatches';
import TennisMatches from './TennisMatches';
import axios from 'axios';
import moment from 'moment'
import Kabaddi from './Kabaddi';
import Support from './Support';
import Binary from './Binary';
import Transparent from '../images/sprite/transparent.gif'
import { Link } from 'react-router-dom';
// import horse from '../images/horsel.6a5281de.svg'
// import MT5 from '../images/mt5.4f09b4e8.svg'

import Cookies from 'universal-cookie';
import BasketballMatches from './BasketballMatches';
import Election from './Election';

const cookies = new Cookies();

require('moment-timezone');

export default function Highlights(props) {
	const [highlightTab, sethighlightTab] = useState(4)
	const [Searchbar, setSearchbar] = useState(false)
	const [countCricket, setCountCricket] = useState(0);
	const [countSoccer, setCountSoccer] = useState(0);
	const [CountBasketball, setCountBasketball] = useState(0);
	const [countTennis, setCountTennis] = useState(0);
	const [newrefresh, setnewrefresh] = useState(true);
	const [casinoWait, setcasinoWait] = useState(false);


	function changecount(cri, socc, tenn, BasBall) {

		setCountCricket(cri);
		setCountSoccer(socc);
		setCountTennis(tenn);
		setCountBasketball(BasBall);
	}

	useEffect(() => {
		axios.all([
			axios.get('https://liveapi247.live/api1/cricketData'),
			axios.get('https://liveapi247.live/api1/soccerData'),
			axios.get('https://liveapi247.live/api1/tennisData'),
			axios.get('https://liveapi247.live/api1/BasketballData')
		])
			.then(axios.spread((obj1, obj2, obj3, obj4) => {

				setnewrefresh(false)
				var count1 = 0;
				var count2 = 0;
				var count3 = 0;
				var count4 = 0;

				obj1.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj2.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})
				obj3.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj4.data.map(item => {
					item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
					return item;
				})

				obj1.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count1 = count1 + 1
					}
				});
				obj2.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count2 = count2 + 1;
					}
				});
				obj3.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count3 = count3 + 1;
					}
				});

				obj4.data.map((item) => {
					if (moment() >= moment(item.marketStartTime)) {
						count4 = count4 + 1;
					}
				});


				changecount(count1, count2, count3, count4);



			}));
	}, [])

	const openSearchbar = () => {
		setSearchbar(true)
	}

	const closeSearchbar = () => {
		setSearchbar(false)
	}


	const openAESexy = (gameI) => {
		if (!props.isLoggedIn) {
			props.checkShowLogin(true);
			return;
		}
		else {
			var utoken = cookies.get('token');
			var user = cookies.get('id');
			var mil = Date.now();

			if (!user || !utoken) {
				return;
			}
			setnewrefresh(true);
			axios.post('https://liveapi247.live/api/gapcasino/game/url', {
				user: user,
				token: utoken,
				platform: 'GPL_DESKTOP',
				lobby_url: window.location.href,
				game_id: gameI

			})
				.then(result => {

					setTimeout(() => { setnewrefresh(false); }, 4000);
					if (result.data.url) {
						// window.open(result.data.url);
						//var newTab=window.open('about:blank');
						window.location.href = result.data.url;

					}


				})
				.catch(e => {

				});

		}
	}





	return (

		<React.Fragment>
			{newrefresh === true &&
				<div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
					<div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
						<div _ngcontent-njs-c67="" class="loading">
							<div></div>
							<div></div>
						</div>
						<p>Loading...</p>
					</div>
				</div>}

			{newrefresh === false &&
				<div _ngcontent-njs-c67="" className='mob-main-content'  >
					{Searchbar === true && <SearchBar closeSearchbar={closeSearchbar} />}
					<div _ngcontent-njs-c67="" id="highlightLabel" className="highlight-fix">

						<a className="a-search" onClick={openSearchbar} >Search</a>
						<div _ngcontent-njs-c67="" id="frame" className="mob-nav-highlight ps ps--theme_default ps--active-x">


							<ul _ngcontent-njs-c67="" id="label">
								{props.isLoggedIn &&
									<li _ngcontent-njs-c67="" class="menu-casino">
										<span _ngcontent-njs-c67="" class="tag-new">New</span>
										<Link to="/CasinoList" id="casinoLoginBtn" ><img class="icon-casino" src={Transparent} />Casino</Link>
									</li>}

								{/* <li class="menu-casino"><span class="tag-new">New</span><Link to="/slots" id="casinoLoginBtn"><img class="icon-bet-game" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw=="/>Slots</Link></li> */}

								<li _ngcontent-njs-c67="" id="highlightTab4" onClick={() => { sethighlightTab(4) }} className={`${(highlightTab === 4) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countCricket}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-cricket" src={Cup} alt='gif' />Cricket</a>
								</li>

								{/* <li id="highlightTab_IPL Winner">
								<Link to="/d/dash/WinnerMarket"><img className="icon-cricket" src={Transparent} />IPL Winner</Link>
								</li> */}

								<li _ngcontent-njs-c67="" id="highlightTab1" onClick={() => { sethighlightTab(1) }} className={`${(highlightTab === 1) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countSoccer}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-soccer" src={Cup} alt='gif' />Soccer</a>
								</li>
								{/*  800001
						<li id="highlightTab2" onClick={() => { sethighlightTab(3) }} className={`${(highlightTab === 3) ? "select" : "null"}`}>
							<span id="tagLive" className="mob-live-number"><strong></strong>{countTennis}</span>
							<a ><img className="icon-tennis" src={Cup} alt='gif' />E-Soccer</a>
						</li> */}


								{/* <li id="highlightTab137" onClick={() => { sethighlightTab(3) }}className={`${(highlightTab === 3) ? "select" : "null"}`}>
						<span id="tagLive" className="mob-live-number" style={{}}><strong />8</span>
						<a href="#" neua="E-Soccer"><img className="icon-esoccer" src={Cup}/>V-Soccer 
						</a>
						</li> */}

								<li _ngcontent-njs-c67="" id="highlightTab2" onClick={() => { sethighlightTab(2) }} className={`${(highlightTab === 2) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{countTennis}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-tennis" src={Cup} alt='gif' />Tennis</a>
								</li>

								{/* <li _ngcontent-njs-c67="" id="highlightTab2" onClick={() => { sethighlightTab(7522) }} className={`${(highlightTab === 7522) ? "select" : "null"}`}>
									<span _ngcontent-njs-c67="" id="tagLive" className="mob-live-number"><strong></strong>{CountBasketball}</span>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" className="icon-tennis" src={Cup} alt='gif' />Basketball</a>
								</li> */}


								{/* <li _ngcontent-njs-c67="" id="highlightTab3" onClick={() => { sethighlightTab(5) }} className={`${(highlightTab === 5) ? "select" : "null"}`}>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" class="icon-Kabaddi" src={horse} />Horse Racing</a>
								</li> */}
								{/* <li _ngcontent-njs-c67="" id="highlightTab2378961" onClick={() => { sethighlightTab(2378962) }} className={`${(highlightTab === 2378962) ? "select" : "null"}`}>
								<a _ngcontent-njs-c67=""  ><img _ngcontent-njs-c67="" class="icon-election" src={Transparent} />Election</a>
								</li> */}
								{/* <li _ngcontent-njs-c67="" id="highlightTab2378961" onClick={() => { sethighlightTab(7) }} className={`${(highlightTab === 7) ? "select" : "null"}`}>
									<a _ngcontent-njs-c67="" ><img _ngcontent-njs-c67="" class="icon-mt5" src={MT5} />MT5</a>
								</li> */}
							</ul>

							<div _ngcontent-njs-c67="" className="ps__scrollbar-x-rail"><div _ngcontent-njs-c67="" className="ps__scrollbar-x"  ></div></div><div _ngcontent-njs-c67="" className="ps__scrollbar-y-rail" ><div _ngcontent-njs-c67="" className="ps__scrollbar-y"  ></div></div></div>
					</div>

					<div _ngcontent-njs-c67="" className="mob-nav-highlight_list" style={{ height: 'auto', backgroundColor: 'pink' }}  >
						<h3>  Highlights</h3>

						<ul _ngcontent-njs-c67="" id="highlightList" className="mob-sports-highlight-list">
							{highlightTab === 4 && <CricketMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 1 && <SoccerMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 2 && <TennisMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{highlightTab === 7522 && <BasketballMatches isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState} />}
							{/* {highlightTab === 2378962 && <Election isLoggedIn={props.isLoggedIn} checkShowLogin={props.checkShowLogin} changeState={props.changeState}/>} */}
						</ul>
					</div>
					{/* <Support handlepolicy={props.handlepolicy} /> */}

				</div>}
		</React.Fragment>
	)
}
