import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment';
import Loading from "../images/sprite/loading40.gif"

require('moment-timezone');



export default function TennisMatches(props) {
  const [match, setMatch] = useState([]);
  const [loading, setloading] = useState(false);
  const [ScoreData, setScoreData] = useState([])

  // vvvv


  const handleRefresh = () => {
    setloading(true);

  }
  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api1/tennisData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);


      })


      .catch(error => console.log('error'));


    axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));
  }, []);



  return (
    <div>
      {loading === true && <div id="oneClickLoading" class="loading-wrap" ><ul class="loading"><li><img src={Loading} alt="Place_bet" /></li><li>Loading...</li></ul></div>}

      {match.map(function (item, id) {
        // console.log('rendering');
        let customScore = '';
        let elapsTime = '';
        let filteredData = ScoreData &&
          ScoreData.filter(x => String(x.eventId) === String(item.eventId));
        // let filteredData = [];
        if (filteredData && filteredData.length > 0) {
          customScore = filteredData[0].score;
          // elapsTime = filteredData[0].timeElapsed;
        }
        var showColor = false;

        if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
          item.runner1BackRate1 = '\u00A0';
        }
        if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
          item.runner1LayRate1 = '\u00A0';
        }
        if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
          item.runner2BackRate1 = '\u00A0';
        }
        if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
          item.runner2LayRate1 = '\u00A0';
        }
        if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
          item.tieBackRate1 = '\u00A0';
        }
        if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
          item.tieLayRate1 = '\u00A0';
        }


        if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
          showColor = true;
        }

        var matchTime;
        var today = moment();
        var tommorow = moment().add(1, 'day');

        if (moment(item.marketStartTime).isSame(today, 'day')) {
          matchTime = moment(item.marketStartTime).format('LT');
        }
        else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
          matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
        }
        else {
          matchTime = item.marketStartTime;
        }



        return (
          <dl key={id} className={`sportsgame-list-content-rate ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
            <dt className="tennis-col">
              {moment() >= moment(item.marketStartTime) &&
                <img id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}
              {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}



              {moment() >= moment(item.marketStartTime) && <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>{item.runnerName1}
                <span class="in_play" style={{ marginLeft: "3px" }}>{customScore !== '' ? customScore : 'v'}</span> {item.runnerName2}</a>}
              {moment() < moment(item.marketStartTime) && <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>{item.runnerName1}
                {item.eventName}</a>}
              {moment() >= moment(item.marketStartTime) &&
                <span id="dateTimeInfo" class="sportsgame-time-list"><span class="in_play">In-Play</span></span>}
              {moment() < moment(item.marketStartTime) && <span class="sportsgame-time-list  ">{matchTime}</span>}
              {moment() >= moment(item.marketStartTime) &&
                <span class="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}

            </dt>
            <dd id="selectTemp" class="sport-odds">

              <a id="back1_0" class="back-button " >{item.runner1BackRate1}</a>
              <a id="lay1_0" class="lay-button " >{item.runner1LayRate1}</a>
            </dd>

            <dd class="last-col">
              <a id="back2_0" class="back-button ">{item.runner2BackRate1}</a>
              <a id="lay2_0" class="lay-button " >{item.runner2LayRate1}</a>
            </dd>
            <dd class="info-icon">
              <a class="add-favourite " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
            </dd>
          </dl>


        )
      })}
    </div>

  )
}


