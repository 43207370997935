import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'
import { trim } from 'jquery';

export const AnnounceNews = (props) => {

    const [Announcement, setAnnouncement] = useState([])

    useEffect(() => {

        axios.post('https://liveapi247.live/api1/news', {
            id: props.userid,
            token: props.utoken,

        })
            .then(result => {
                // if(result.status === 210){
                var arr = [];
                //  console.log(result.data);
                result.data.map((item) => {
                    arr.push(item)
                })
                //	
                setAnnouncement(arr);
                //  }

            }
            ).catch(e => {
                //setIsError(true);
            });

    }, [])



    return (


        <React.Fragment>

            <div id="announcementPopUp" className="overlay">
                <div className="mob-news-popup marquee-pop">
                    {/* mob-news-popup */}

                    {/* <header class="announce-header"><h1>Announcement</h1><a class="close" href="vueRiskManagement.html">Close</a></header>  */}

                    <div _ngcontent-njs-c67="" className="side-head">
                        <h3 _ngcontent-njs-c67="" className="a-depth">Announcement</h3>
                        <a _ngcontent-njs-c67="" className="close ui-link" onClick={() => { props.handleOpen(false) }} href="javascript:$j('#announcementPopUp').css('display', 'none');">Close</a>
                    </div>

                    <div _ngcontent-njs-c67="" className="mob-announce-content" id="announcementContent">
                        {Announcement.map(function (item, id) {

                            var Date = moment(item.date).format('ll');
                            const myArray = Date.split(" ", 3);


                            var FinalDate = myArray[1].replace(/,\s*$/, "");

                            return (
                                <div _ngcontent-njs-c67="" id="announcementTemplate" className="article-wrap">
                                    <dl _ngcontent-njs-c67="" className="article-date">
                                        <dt _ngcontent-njs-c67="" id="date_0">{FinalDate}</dt>
                                        <dd _ngcontent-njs-c67="" id="month_0">{myArray[0]}</dd>
                                        <dd _ngcontent-njs-c67="" id="year_0">{myArray[2]}</dd>
                                    </dl>
                                    <p _ngcontent-njs-c67="" id="textByLanguageType_0">{item.message}</p>
                                </div>
                            )
                        })}



                    </div>
                    <div>
                        <ul _ngcontent-njs-c67="" id="pageNumberContent" className="pages">
                            <li _ngcontent-njs-c67="" id="prev">
                                <a _ngcontent-njs-c67="" className="ui-link disable" style={{ pointerEvents: 'none' }}>Prev</a></li>
                            <li _ngcontent-njs-c67="" id="pageNumber">
                                <a _ngcontent-njs-c67="" className="ui-link select" style={{ pointerEvents: 'none', backgroundColor: '#222222', color: '#rgb(254 184 9)' }}>1</a></li>
                            <li _ngcontent-njs-c67="" id="next">
                                <a _ngcontent-njs-c67="" className="ui-link disable" style={{ pointerEvents: 'none' }}>Next</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
