import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Transparent from '../images/sprite/transparent.gif';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import sexyAe from '../images/casinogif/sexybacarate.webp'
import roulletee from '../images/casinogif/casinew/ezugi.webp'
import blackjack from '../images/casinogif/casinew/blackjack.jpeg'
import Loading from "../images/sprite/loading40.gif"
import patti20 from '../images/casino/teenpatti20.jpeg';
import colorgame from '../images/casinogif/casinopng/Color_Game.png'
import Sports from '../images/Banner/bannersports.jpeg';
import numbermatka from '../images/casinogif/casinew/numbermatka.png'
import Kings1 from '../images/casinogif/casinopng/7_Up_7_Down.png'
import anderbahar from '../images/casinogif/casinopng/Andar_Bahar.png'
import mineswiper from '../images/casino/mineswiper.jpeg'
import Kings5 from '../images/casinogif/casinew/king-sicbo.png'
import Kings6 from '../images/casinogif/casinopng/Coin_Toss.png'
import Kings7 from '../images/casinogif/casinew/European_Roulette.webp'
import cards32 from '../images/casinogif/casinew/cards_32.png'
import Kings8 from '../images/kv/cardMatka.png'
import Cookies from 'universal-cookie';
import Evolutiongame from '../images/casinogif/casinew/evopng.png'
import KingMakers from '../images/casinogif/casinew/kingmaker.png'
import heist from '../images/casinogif/casinew/heist.png'
import ezugicasino from '../images/casino/ezugi.webp'
import hilo from '../images/casinogif/casinew/Cards_Hi_Lo.png'
import jililobi from '../images/casino/banner_JILI-full.png'
import numberking from '../images/casinogif/casinew/number-matka.png'
import bigsmall from '../images/casino/big-small.jpeg'
import pattijoker from '../images/casino/teenpatti.jpeg'
import up7down from '../images/casino/7updown.png'
import dragontiger from '../images/casino/dragon-tiger.jpeg'
import callBreakquick from '../images/casinogif/casinew/Callbreak.png'
import SicboJili from '../images/casinogif/casinew/sicbo-half.png'
import BaccaratJili from '../images/casino/baccaret.jpeg'
import updownjili from '../images/casinogif/casinew/UpDown.jpeg'
import andarbaharjili from '../images/casinogif/Andar_Bahar.webp'
import ak47 from '../images/casinogif/casinew/AK47.png'
import royalfish from '../images/casinogif/casinew/Royal_Fishing.png'
import jackpotfish from '../images/casinogif/casinew/Jackpot_Fishing.png'
import megafish from '../images/casinogif/casinew/Mego-Fishing.png'
import starfish from '../images/casinogif/casinew/All_star_Fishing.png'
import happyfish from '../images/casinogif/casinew/Happy_Fishing.png'
import dragonfor from '../images/casinogif/casinew/dragon-fortune.webp'

const cookies = new Cookies();

const photos = [
    // {
    //     name: 'photo 1',
    //     url: [IPL]
    // },
    // {
    //     name: 'photo 2',
    //     url: [IPL2]
    // },
    // {
    //     name: 'photo 3',
    //     url: [Womnesbanner]
    // },
    // {
    //     name: 'photo 7',
    //     url: [Matka]
    // },
    // {
    //     name: 'photo 8',
    //     url: [NumberMatka]
    // },
    // {
    //     name: 'photo 9',
    //     url: [andarbahar]
    // }


]

const settings = {
    dots: true,
    slide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    className: 'slidesN'
}

export default function Bannerpage(props) {
    const [Socialicon, setSocialicon] = useState(1);
    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [casinoWait, setcasinoWait] = useState(false);
    const [getBanners, setBanners] = useState([])

    const history = useHistory();

    function changecount(cri, socc, tenn) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
    }

    // Fetch Sites Banner
    const getBannerSiteImages = async () => {
        try {
            let resp = await axios.post(
                'https://liveapi247.live/api1/getBanner',
                {
                    siteName: 'BAZIGARADMIN'
                }
            );
            if (resp) {
                // cosnsole.log(resp.data)
                if (resp.status == 200) {
                    return resp.data;
                }
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    useEffect(async () => {
        setBanners(photos);
        let val = await getBannerSiteImages();
        if (val.length > 0) {
            let newResp = [{ url: val[0].banner1 }, { url: val[0].banner2 }, { url: val[0].banner3 }, { url: val[0].banner4 }, { url: val[0].banner5 }, { url: val[0].banner6 }, { url: val[0].banner7 }, { url: val[0].banner8 }, { url: val[0].banner9 }, { url: val[0].banner10 }]
            setBanners(newResp)
        }
    }, []);


    useEffect(() => {
        axios.all([
            axios.get('https://liveapi247.live/api1/cricketData'),
            axios.get('https://liveapi247.live/api1/soccerData'),
            axios.get('https://liveapi247.live/api1/tennisData')
        ])
            .then(axios.spread((obj1, obj2, obj3) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                changecount(count1, count2, count3);
            }));
    }, [])

    return (
        <React.Fragment>

            {casinoWait === true &&
                <ul class="loading">
                    <li><img src={Loading} /></li>
                    <li>Loading...
                    </li>
                    <br />
                    <li><span id="progress"></span></li>
                </ul>

            }
            {/* { casinoWait && <div class="bl-Preloader" id="__-plContainer">
         <div class="bl-Preloader_Header">
           <div class="bl-Preloader_ProductHeader "></div>
               <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_Maingamexlogo "></div>
           </div>
          </div>
         <div class="bl-Preloader_SpinnerContainer ">
           <div class="bl-Preloader_Spinner "></div>
         </div>
       </div> } */}

            {/* <div class="bl-Preloader" id="__-plContainer">
    <div class="bl-Preloader_Header">
        <div class="bl-Preloader_ProductHeader "></div>
        <div class="bl-Preloader_MainHeader ">
            <div class="bl-Preloader_Maingamexlogo "></div>
        </div>
    </div>
    <div class="bl-Preloader_SpinnerContainer ">
        <div class="bl-Preloader_Spinner "></div>
    </div>
</div> */}
            {/* <div class="overlay" style={{display: "flex",justifyContent:'center',alignItems:'center',backgroundColor:'rgb(0 0 0 / 90%)'}}>  
                            <div id="page-wrap">
                    <div class="meter red">
                        <span style={{width: "100%"}}></span>
                    </div>
                        </div>
                        </div> */}


            <div _ngcontent-lst-c68="" id="centerColumn" class="centar-content casinozone">

                <div _ngcontent-lst-c68="" id="overWrap" class="lit-content-center" style={{ height: "calc(100% - 0px)" }}>
                    <div _ngcontent-lst-c68="" class="images-grid">
                        <Slider  {...settings}>
                            {getBanners.filter((x, i) => x.url !== null).map((photo, id) => {
                                return (
                                    <div _ngcontent-njs-c67="" key={id}>
                                        <img _ngcontent-njs-c67="" src={photo.url} alt="cric" style={{ height: '192px' }} />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div _ngcontent-lst-c68="" class="casinozone-wrap-simple bg-half">
                        <a _ngcontent-lst-c68="" href="/" style={{ cursor: "pointer" }}>
                            <dl _ngcontent-lst-c68="" id="onLiveBoard" class="live_events">
                                <dt _ngcontent-lst-c68="" >
                                    <p _ngcontent-lst-c68="" class="live_icon"><span></span> LIVE</p>
                                </dt>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_CRICKET">
                                    <p _ngcontent-lst-c68="" >Cricket</p><span id="count">{countCricket}</span></dd>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_SOCCER">
                                    <p _ngcontent-lst-c68="" >Soccer</p><span id="count">{countSoccer}</span></dd>

                                <dd _ngcontent-lst-c68="" id="onLiveCount_TENNIS">
                                    <p _ngcontent-lst-c68="" >Tennis</p><span id="count">{countTennis}</span></dd>

                            </dl>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Sports</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" src={Sports} alt="" draggable="false" />
                        </a>



                        <a _ngcontent-lst-c68="" style={{ cursor: "pointer" }} class="mob-casino-half" href={props.isLoggedIn ? '/game-play/S2/LC/AWC Sexy/0' : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Ae Sexy</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" className='img-bg' src={sexyAe} alt="" draggable="false" />
                        </a>


                        {/* <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? "/game-play/GE/LC/Gaming Evolution/0" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Evolution Gaming</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="bg-img-evo" src={Evolutiongame} alt="" draggable="false" />
                        </a> */}
                        {/* <a _ngcontent-lst-c68="" style={{ cursor: "pointer", gridColumn: 'span 2' }}
                        // href={props.isLoggedIn ? "/game-play/EZ/LC/Ezugi/0" : '/'}
                        >
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt>Ezugy</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" src={roulletee} alt="" draggable="false" />
                        </a> */}
                        <a _ngcontent-lst-c68="" style={{ cursor: "pointer", gridColumn: 'span 2' }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/0" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt>KingMakers</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="king-img" src={KingMakers} alt="" draggable="false" />
                        </a>


                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Sicbo" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Sicbo</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="bg-scbo" src={Kings5} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Heist" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Heist</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="Heist-img" src={heist} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_7_Up_7_Down" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >7 Up 7 Down</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="updown-img" src={Kings1} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Coin_Toss" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Coin Toss</dt>
                                <dd _ngcontent-lst-c68="" >Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" className='coin-img' src={Kings6} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Triple_Chance" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt>Number Matka</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="number-bg" src={numbermatka} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Poker_Roulette" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt>Card Matka</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" src={Kings8} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Andar_Bahar" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt>Ander Bahar</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img _ngcontent-lst-c68="" class="bg-img-evo" src={anderbahar} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Blackjack" : '/'}>
                            <dl _ngcontent-lst-c68="" class="casino-title">
                                <dt _ngcontent-lst-c68="" >Blackjack</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={blackjack} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? "/game-play/EZ/LC/Ezugi/0" : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Ezugi</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={ezugicasino} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Cards_Hi_Lo" : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Card Hilo</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={hilo} alt="" draggable="false" />
                        </a>


                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? '/game-play/RE/SL/KingMakers/KMQM_Minesweeper' : '/'}>
                            <dl class="casino-title">
                                <dt>mine Swiper</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={mineswiper} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_Color_Game" : '/'}>
                            <dl class="casino-title">
                                <dt>Color Game</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={colorgame} class="color-img" alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_European_Roulette" : '/'}>
                            <dl class="casino-title">
                                <dt>Roulette</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Kings7} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? "/game-play/RE/SL/KingMakers/KMQM_32_Cards" : '/'}>
                            <dl class="casino-title">
                                <dt>32 Cards</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={cards32} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" style={{ cursor: "pointer" }} href={props.isLoggedIn ? '/game-play/JJ/SL/Jili/0' : '/'}>
                            <dl class="casino-title">
                                <dt>jili</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={jililobi} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" style={{ cursor: "pointer" }} href={props.isLoggedIn ? '/game-play/JJ/SL/Teenpatti/161' : '/'}>
                            <dl class="casino-title">
                                <dt>TeenPatti 20-20</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={patti20} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Number King/111' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Number King</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={numberking} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Big Small/118' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Big Small</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={bigsmall} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Big Small/118' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Teenpatti joker</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={pattijoker} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/7Up 7Down/124' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>7Up7Down</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={up7down} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Dragon Tiger/123' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Dragon & Tiger</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={dragontiger} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Callbreak Quick/160' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>CallBreak Quick</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={callBreakquick} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Sic Bo/125' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Sic Bo</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={SicboJili} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Sic Bo/125' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Bacarrat</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={BaccaratJili} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/7up7down/124' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>7up7down</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={updownjili} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Andar Bahar/79' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Andar Bahar</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={andarbaharjili} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/AK47/75' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>AK47</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={ak47} alt="" draggable="false" />
                        </a>

                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Royal Fishing/1' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Royal Fishing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={royalfish} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Royal Fishing/1' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Jackpot Fishing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={jackpotfish} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Mega Fishing/74' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Mega Fishing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={megafish} className='mega-twenti' alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/All-star Fishing/119' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>All Star Fishing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={starfish} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Happy Fishing/119' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Happy Fishing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={happyfish} alt="" draggable="false" />
                        </a>
                        <a _ngcontent-lst-c68="" class="mob-casino-half" href={props.isLoggedIn ? '/game-play/JJ/SL/Dragon Fortune/60' : '/'} style={{ cursor: "pointer" }}>
                            <dl class="casino-title">
                                <dt>Dragon Fortune</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={dragonfor} className='dragon-img' alt="" draggable="false" />
                        </a>



                        {/* <a style={{ cursor: "pointer", cursor: "pointer", height: '255px' }}>
                            <dl class="casino-title">
                                <dt>Horse Racing</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={Banneryc} alt="" draggable="false" />
                        </a> */}

                        {/* <a style={{ cursor: "pointer", cursor: "pointer", height: '255px' }}>
                            <dl class="casino-title">
                                <dt>Kabaddi</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={kabaddi} alt="" draggable="false" />
                        </a> */}








                        {/* <a style={{ cursor: "pointer", gridColumn: "span 4", height: '187px' }}>
                            <dl class="casino-title">
                                <dt>Bird Fight</dt>
                                <dd>Play Now</dd>
                            </dl>
                            <img src={birdfight} alt="" draggable="false" />
                        </a> */}
                    </div>

                    <div _ngcontent-lst-c68="" class="footer" >


                        <div id="supportWrap" className="support-wrap">
                            <div className="support-service">
                                <a id="support_email" onMouseOver={() => { setSocialicon(0) }} className={`support-mail  ${(Socialicon === 0) ? "open" : "null"}`} >
                                    <img src={Transparent} title="Email" /></a>
                                <a id="support_whatsapp" onMouseOver={() => { setSocialicon(1) }} className={`support-whatsapp  ${(Socialicon === 1) ? "open" : "null"}`} >
                                    <img src={Transparent} title="WhatsApp" /></a>
                                <a
                                    id="support_telegram" onMouseOver={() => { setSocialicon(2) }} className={`support-telegram  ${(Socialicon === 2) ? "open" : "null"}`} >
                                    <img src={Transparent} title="Telegram" /></a>
                                <a id="support_email" onMouseOver={() => { setSocialicon(3) }} className={`support-skype  ${(Socialicon === 3) ? "open" : "null"}`} >
                                    <img src={Transparent} title="skype" /></a>
                                <a
                                    id="support_email" onMouseOver={() => { setSocialicon(4) }} className={`support-ig  ${(Socialicon === 4) ? "open" : "null"}`} >
                                    <img src={Transparent} title="skype" /></a>
                            </div>
                            <div className="support-info">
                                <div id="supportDetail_telegram" className="support-detail open">
                                    {Socialicon === 1 && <a className="ui-link" href="000">
                                        <span style={{ color: 'rgb(102, 102, 102)', textDecoration: 'underline' }} />000</a>}
                                    {Socialicon === 2 && <a className="ui-link" href="https://t.me/gamex_live">
                                        <span style={{ color: 'rgb(102, 102, 102)', textDecoration: 'underline' }} />gamex_live</a>}
                                </div>
                            </div>
                        </div>

                        <div _ngcontent-lst-c67="" class="power-content" style={{ width: "109px" }}>
                            <h3 _ngcontent-lst-c68="" style={{ backgroundImage: 'none', lineHeight: '15px', color: '#484745', marginTop: '-5px', textAlign: 'left' }}><span>Powered by</span><img src={Transparent} alt="poweredby" /></h3>
                        </div>
                        <div _ngcontent-lst-c68="" class="browser-footer-content">

                            <img _ngcontent-lst-c68="" src={Transparent} alt="gif" />
                            <br></br>
                            Our website works best in the newest and last prior version of these browsers: <br></br>Google Chrome.
                        </div>


                        <ul _ngcontent-lst-c68="" className="mob-inform-link">
                            <li _ngcontent-lst-c68=""  ><a >KYC</a></li>
                            <li _ngcontent-lst-c68=""  ><a >About Us</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Privacy Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Responsible Gaming</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Terms and Conditions</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Underage Gaming Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Self-Exclusion Policy</a></li>
                            <li _ngcontent-lst-c68=""  ><a >Rules and Regulations</a></li>









                        </ul>


                        <ul _ngcontent-lst-c68="" class="RnR-link">
                            <li _ngcontent-lst-c68=""><a >Rules and Regulations</a></li>
                        </ul>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}
