import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Account(props) {
    const [refresh, setrefresh] = useState(true);


    useEffect(() => {

        setTimeout(() => { setrefresh(false); }, 500);
    }, [])


    return (
        <React.Fragment>


            {refresh === true &&
                <div class="loading-overlay" id="loading">
                    <div class="loading-wrap" style={{ display: " flex" }}>
                        <div class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>}

            {refresh === false &&
                <div _ngcontent-njs-c67="" class="mob-main-content">

                    <div _ngcontent-njs-c67="" class="path-wrap">
                        <p class="account-id">
                            <span>{props.userid}</span>
                            <span class="time-zone"></span>
                        </p>
                    </div>

                    <ul _ngcontent-njs-c67="" class="sports-side-menu-list">
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/myprofile" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;My Profile</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/balance" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Balance Overview</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/AccountStatement" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Account Statement</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/mybets" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;My Bets</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/bethistory" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Bets History</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67=""  to="/d/dash/ProfitLoss" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Profit &amp; Loss</Link></li>
                    <li _ngcontent-njs-c67="" ><Link _ngcontent-njs-c67="" to="/d/dash/activitylog" target="_blank"><i aria-hidden="true" style={{ marginTop: ' 2px' }}></i>&nbsp;Activity Log</Link></li>
                    </ul>

                    <a id="logout" className="logout" onClick={() => { props.handleLogout(); }}>LOGOUT
                    </a>
                </div>}

        </React.Fragment>
    )
}
