import React, { useState, useEffect, memo } from 'react'
import Axios from 'axios';
import liveImg from '../../../images/sprite/cover-live.png';
import Transparent from '../../../images/sprite/transparent.gif';
import moment from 'moment';

const Tvstream = (props) => {
    const url = window.location.href;
    const para = url.split('/');
    const eventT = para[6];
    const eventid = para[7];
    const marketID = para[8];
    const [ChannelId, setChannelId] = useState(null);
    const [ScoreData, setScoreData] = useState()
    const [ScoreId, setScoreId] = useState('')
    const [livemarpin, setlivemarpin] = useState(false)
    const [chId, setchId] = useState(null);



    const handlelivempin = () => {
        if (!props.isLoggedIn) {
            props.checkShowLogin(true);
            return;
        } else {
            setlivemarpin(!livemarpin)
        }
    }

    useEffect(() => {
        async function fetchData() {

            if (!eventid) {
                props.changeLiveStreaming(null);
                // props.setliveStream(null);
                return;
            }
            await Axios.post("https://liveapi247.live/api1/livestreaming", {
                eventid: eventid,
            })
                .then((result) => {
                    if (result.status === 210) {

                        if (result.data[0].tvId && result.data[0].tvId != '') {
                            props.changeLiveStreaming(result.data[0].tvId);
                            setScoreData(result.data[0].scoreId);

                        }
                    }
                })
                .catch((e) => {
                    //setIsError(true);
                });
            if (!ScoreData || !ChannelId || !chId) {
                await Axios.post("https://liveapi247.com/api1/livestreaming", {
                    eventid: eventid,
                })
                    .then(result => {
                        if (result.status === 210) {
                            props.changeLiveStreaming(result.data[0].tvId)
                            setScoreId(result.data[0].scoreId);
                            setchId(result.data[0].tvId2);
                            console.log("scoreeee", result.data[0].scoreId)

                        }
                    })
                    .catch((e) => {
                        //setIsError(true);
                    });
            }



            if (!eventid) return;
            await Axios.get(`https://lt-fn-cdn001.akamaized.net/techablesoftware/en/Etc:UTC/gismo/match_info/m` + eventid).then((result) => {
                var MatchId = result.data.queryUrl;
                var str = MatchId;
                var matches = str.match(/(\d+)/);
                setScoreId(matches[0]);

            }).catch((err) => {
                // console.log(err);
            })

            if (!eventid) return;
            await Axios.post('https://liveapi247.live/api1/getTvId', {
                eid: eventid
            })
                .then(result => {

                    if (result.status === 200) {
                        setChannelId(result.data.data.cid);
                    }
                }
                ).catch(e => {

                });

        }
        fetchData();
    }, [eventid]);
    let multiChannelId = chId != null ? chId : ChannelId;


    return (
        <>
            {multiChannelId !== null && props.Tvopen && props.isLoggedIn === true && <div _ngcontent-njs-c67="" id="streamingBox" className="tv-fix" style={props.isLoggedIn ? { top: '21.6vw' } : { top: '14.6vw' }} onPointerEnter={() => { props.TvControl() }}>
                <div className="tv-wrap">
                    <div _ngcontent-njs-c67="" id="streamContainer" className="tv-box" >
                        <div _ngcontent-njs-c67="" id="tv" className="tv">
                            <img src={liveImg} style={{ width: '100%', height: '100%', position: 'absolute' }} />
                            {/*<ul _ngcontent-njs-c67="" className="tv-control">*/}
                            {/*    <li _ngcontent-njs-c67="" ><a _ngcontent-njs-c67="" className="close" id="closeStreamingBox" onClick={() => { props.ChangeSetTv() }}>Close</a></li>*/}
                            {/*</ul>*/}


                            <iframe id="myframe" style={{ height: '100%', width: '100%', border: 0, zIndex: 100, position: 'absolute' }}
                                autoPlay="" preload="" playsinline=""
                                title="mob-live-stream"
                                allowFullScreen={false}
                                src={eventT == 0 ? `https://ss247.info/api/Nstream/2/${multiChannelId}` : `https://ss247.life/api/4b50fe2720dbe6dfdb23b57cd8d62cafc564ac15/Nstreamapi.php?chid=${multiChannelId}`}
                                // src={props.liveStream}
                                // src={
                                //     thandler[0].T_Mode == 1 && thandler[0].S_discard == 0 ?
                                //         thandler[0].Source1 + multiChannelId + thandler[0].Source2 + getmyIPaddress
                                //         :
                                //         thandler[0].T_Mode == 1 && thandler[0].S_discard == 1 ?
                                //             thandler[0].Source2 + getmyIPaddress
                                //             :
                                //             thandler[0].Source1 + multiChannelId
                                // }
                                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                            </iframe>

                        </div>
                    </div>
                </div>
            </div>}
            {eventT == 4 && <h4 id="gameInfo" className="game-info">Cricket<ul id="infoIcon" className="info-icon">
                {moment() >= moment(props.marketStartTime) && (<li id="inPlayIcon" style={{}}>
                    <img className="info-inplay" src={Transparent} />In-Play
                </li>)}



                {moment() <= moment(props.marketStartTime) && (<li><span id="lowLiquidityTag" className="game-low_liq">Low</span></li>)}
            </ul></h4>}
            {eventT == 1 && <h4 id="gameInfo" className="game-info">Soccer<ul id="infoIcon" className="info-icon">
                {moment() >= moment(props.marketStartTime) && (<li id="inPlayIcon" style={{}}>
                    <img className="info-inplay" src={Transparent} />In-Play
                </li>)}



                {moment() <= moment(props.marketStartTime) && (<li><span id="lowLiquidityTag" className="game-low_liq">Low</span></li>)}
            </ul></h4>}

            {eventT == 2 && <h4 id="gameInfo" className="game-info">Tennis<ul id="infoIcon" className="info-icon">
                {moment() >= moment(props.marketStartTime) && (<li id="inPlayIcon" style={{}}>
                    <img className="info-inplay" src={Transparent} />In-Play
                </li>)}

                {moment() <= moment(props.marketStartTime) && (<li><span id="lowLiquidityTag" className="game-low_liq">Low</span></li>)}
            </ul></h4>}

            {/* iframe tv end  */}
            {eventT != '2378962' &&
                <div _ngcontent-njs-c67="" id="matchTrackerWrap" className="match-tracker-wrap" >
                    {(ScoreId && ScoreId !== '' && ScoreId !== undefined) && ScoreId != eventid ? (

                        //    <iframe style={{ height: '180px', width: '100%', border: 0 }}
                        //     autoPlay="" preload="" playsinline=""
                        //     title="mob-live-stream"
                        //     allowFullScreen={false}
                        //     src={`https://sky-entertainment.gratitude-software.in/scoreboard?sportId=${eventT}&eventId=${eventid}&marketId=${marketID}`}
                        //     sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                        // </iframe>

                        <iframe id="myframe" style={{ height: '193px', width: '100%', border: 0, background: ' #1e1e1e' }}
                            autoPlay="" preload="" playsinline=""
                            title="mob-live-stream"
                            allowFullScreen={false}
                            src={`https://scorecard.clickbetexch.com/get-scorecard-iframe/${eventT}/${eventid}/${ScoreId}`}
                            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                        </iframe>
                    ) : ScoreData &&
                    (<iframe id="myframe" style={{ height: '193px', width: '100%', border: 0, background: ' #1e1e1e' }}
                        autoPlay="" preload="" playsinline=""
                        title="mob-live-stream"
                        allowFullScreen={false}
                        src={`https://scorecard.clickbetexch.com/get-scorecard-iframe/${eventT}/${eventid}/${ScoreData}`}
                        sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups">
                    </iframe>)}

                    <ul _ngcontent-njs-c67="" className="fm-match-button">
                        <li _ngcontent-njs-c67="" >
                            <a _ngcontent-njs-c67="" id="liveMultiMarketPin" onClick={handlelivempin} style={{ cursor: 'pointer' }} name="gameHeadPin" className={`favourite-pin ${livemarpin ? "select" : ""}`} title="Add to Multi Markets" eventtype="4" eventid="30062362" marketid="1.174202586">Pin</a></li>
                        <li _ngcontent-njs-c67="" >
                            <a _ngcontent-njs-c67="" style={{ cursor: 'pointer' }} onClick={() => { props.handleRefreshhh(); }} className="fm-refresh-button">Refresh</a></li>
                    </ul>

                </div>}


        </>
    )
}

export default memo(Tvstream)
