import React, { useEffect, useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Loading from "../images/sprite/loading40.gif"
import moment from 'moment';
require('moment-timezone');


export default function InplayContent(props) {


  const [CricketBetslip, setCricketBetslip] = useState(false);
  const [SoccerBetslip, setSoccerBetslip] = useState(false);
  const [TennisBetslip, setTennisBetslip] = useState(false);
  const [BasketBetslip, setBasketBetslip] = useState(false);
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [basketball, setBasketball] = useState([])
  const [fancy, setFancy] = useState([]);
  const [bookmaker, setbookmaker] = useState([])
  const [refresh, setrefresh] = useState(true);
  const [ScoreData, setScoreData] = useState([])

  useEffect(() => {
    axios
      .get(`https://liveapi247.live/api1/tennisData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }

        });
        setTennis(obj);
      })
      .catch((error) => console.log('error'));

    axios
      .get(`https://liveapi247.live/api1/soccerData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log('error'));

    axios
      .get(`https://liveapi247.live/api1/cricketData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });

        setcricket(obj);
      })

      .catch((error) => console.log('error'));
    // basketball api
    axios
      .get(`https://liveapi247.live/api1/basketballData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });

        setBasketball(obj);
      })

      .catch((error) => console.log('error'));

    // 
    axios
      .get(`https://liveapi247.live/api1/fancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log('error'));


    axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));



    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));
  }, []);



  return (
    <React.Fragment>

      {refresh === true &&
        <div id="loading" class="loading-wrap" style={{ top: '100px' }}>
          <ul class="loading">
            <li><img src={Loading} /></li>
            <li>Loading...
            </li>
            <br />
            <li><span id="progress"></span></li>
          </ul>
        </div>}
      <div className="over-wrap" style={{ height: 'calc(100% - 74px)' }}>
        {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || basketball.length > 0) && refresh === false && <React.Fragment>

          {cricket.length > 0 && <div className={`game-wrap col3 ${(CricketBetslip === true) ? "close" : "null"}`}>

            <h3 _ngcontent-njs-c64="" onClick={() => { setCricketBetslip(!CricketBetslip) }}>
              <a _ngcontent-njs-c64="" class="to-expand ng-binding" toggle-class="">Cricket</a>
            </h3>
            <ul _ngcontent-njs-c64="" class="sports-header">
              <li _ngcontent-njs-c64="" class="col-game"></li>
              <li _ngcontent-njs-c64="" class="col-matched" style={{ width: '13.4%' }}></li>
              <li _ngcontent-njs-c64="" class="sport-odds">1</li>
              <li _ngcontent-njs-c64="" class="col-draw">x</li>
              <li _ngcontent-njs-c64="" class="last-col">2</li>
              <li _ngcontent-njs-c64="" class="info-icon"></li>
            </ul>
            <div id="inplayGameList" class="game-list first">
            </div>


            <React.Fragment >
              {cricket.map(function (item, id) {
                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }
                var showColor = false;
                var showFancy = false;
                var isFancy = false;
                var isbookmaker = false;

                if (fancy.includes(item.eventId)) {
                  isFancy = true;

                };
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;

                }

                if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                  showColor = true;
                }
                if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
                  showFancy = true;
                }


                if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
                  item.runner1BackRate1 = '\u00A0';
                }
                if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
                  item.tieBackRate1 = '--';
                }
                if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
                  item.runner2BackRate1 = '\u00A0';
                }
                if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
                  item.runner1LayRate1 = '\u00A0';
                }
                if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
                  item.tieLayRate1 = '--';
                }
                if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
                  item.runner2LayRate1 = '\u00A0';
                }





                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) &&
                      <dl _ngcontent-njs-c64="" class="sportsgame-list-content-rate" key={id}>

                        <dt>

                          {moment() >= moment(item.marketStartTime) &&
                            <img _ngcontent-njs-c64="" id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}

                          <a _ngcontent-njs-c64="" href={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.eventName}</a>
                          {moment() >= moment(item.marketStartTime) &&
                            <span _ngcontent-njs-c64="" id="dateTimeInfo" class="sportsgame-time-list">
                              <span class="in_play">In-Play</span></span>}
                          {moment() >= moment(item.marketStartTime) &&
                            <span class="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}
                          {isFancy && <span class="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>}

                          {isbookmaker && <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>BookMaker</span>}
                        </dt>
                        <dd _ngcontent-njs-c67="" id="matched" class="col-matched" style={{ display: 'block', width: '121px' }}></dd>
                        <dd _ngcontent-njs-c64="" id="selectTemp" class="sport-odds">

                          <a _ngcontent-njs-c64="" id="back1_0" class="back-button ng-binding" >{item.runner1BackRate1}</a>
                          <a _ngcontent-njs-c64="" id="lay1_0" class="lay-button ng-binding" >{item.runner1LayRate1}</a>
                        </dd>
                        <dd _ngcontent-njs-c64="" class="col-draw">
                          <a _ngcontent-njs-c64="" id="btnBack" class="back-button">{item.tieBackRate1}</a>
                          <a _ngcontent-njs-c64="" id="btnLay" class="lay-button">{item.tieLayRate1}</a>
                        </dd>
                        <dd _ngcontent-njs-c64="" class="last-col">
                          <a _ngcontent-njs-c64="" id="back2_0" class="back-button ng-binding">{item.runner2BackRate1}</a>
                          <a _ngcontent-njs-c64="" id="lay2_0" class="lay-button ng-binding" >{item.runner2LayRate1}</a>
                        </dd>
                        <dd _ngcontent-njs-c64="" class="info-icon">
                          <a _ngcontent-njs-c64="" class="add-favourite" style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                        </dd>
                      </dl>}
                  </React.Fragment>
                )
              })}
            </React.Fragment>

          </div>}



          {Soccer.length > 0 && <div className={`game-wrap col3 ${(SoccerBetslip === true) ? "close" : "null"}`}>
            <h3 onClick={() => { setSoccerBetslip(!SoccerBetslip) }}>
              <a _ngcontent-njs-c64="" class="to-expand ng-binding" toggle-class="">Soccer</a>
            </h3>
            <ul _ngcontent-njs-c64="" class="sports-header">
              <li _ngcontent-njs-c64="" class="col-game"></li>
              <li _ngcontent-njs-c64="" class="col-matched" style={{ width: '13.4%' }}></li>
              <li _ngcontent-njs-c64="" class="sport-odds">1</li>
              <li _ngcontent-njs-c64="" class="col-draw">x</li>
              <li _ngcontent-njs-c64="" class="last-col">2</li>
              <li _ngcontent-njs-c64="" class="info-icon"></li>
            </ul>



            {Soccer.map(function (item, id) {
              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }
              var showColor = false;

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showColor = true;
              }

              if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
                item.runner1BackRate1 = '\u00A0';
              }
              if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
                item.tieBackRate1 = '--';
              }
              if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
                item.runner2BackRate1 = '\u00A0';
              }
              if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
                item.runner1LayRate1 = '\u00A0';
              }
              if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
                item.tieLayRate1 = '--';
              }
              if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
                item.runner2LayRate1 = '\u00A0';
              }





              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) &&
                    <dl _ngcontent-njs-c64="" key={id} className={`sportsgame-list-content-rate ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
                      <dt _ngcontent-njs-c64="">
                        {moment() >= moment(item.marketStartTime) &&
                          <img _ngcontent-njs-c64="" id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}
                        {moment() < moment(item.marketStartTime) && <img _ngcontent-njs-c64="" id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}


                        <a _ngcontent-njs-c64="" href={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.runnerName1}<span class="in_play" style={{ marginLeft: '0px' }}>{customScore !== '' ? customScore : 'vs'}</span>{item.runnerName2}</a>

                        {moment() >= moment(item.marketStartTime) &&
                          <span _ngcontent-njs-c64="" id="dateTimeInfo" class="sportsgame-time-list"><span _ngcontent-njs-c64="" class="in_play">In-Play</span></span>}

                        {moment() < moment(item.marketStartTime) && <span _ngcontent-njs-c64="" class="sportsgame-time-list ng-binding ">{item.marketStartTime}</span>}
                        {moment() >= moment(item.marketStartTime) &&
                          <span class="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}

                      </dt>
                      <dd _ngcontent-njs-c67="" id="matched" class="col-matched" style={{ display: 'block', width: '121px' }}></dd>
                      <dd _ngcontent-njs-c64="" id="selectTemp" class="sport-odds">

                        <a _ngcontent-njs-c64="" id="back1_0" class="back-button ng-binding" >{item.runner1BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay1_0" class="lay-button ng-binding" >{item.runner1LayRate1}</a>
                      </dd>
                      <dd _ngcontent-njs-c64="" class="col-draw">
                        <a _ngcontent-njs-c64="" id="btnBack" class="back-button ">{item.tieBackRate1}</a>
                        <a _ngcontent-njs-c64="" id="btnLay" class="lay-button ">{item.tieLayRate1}</a>
                      </dd>
                      <dd _ngcontent-njs-c64="" class="last-col">
                        <a _ngcontent-njs-c64="" id="back2_0" class="back-button ng-binding">{item.runner2BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay2_0" class="lay-button ng-binding" >{item.runner2LayRate1}</a>
                      </dd>
                      <dd _ngcontent-njs-c64="" class="info-icon">
                        <a _ngcontent-njs-c64="" class="add-favourite " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                      </dd>
                    </dl>}
                </React.Fragment>


              )
            })}

          </div>}




          {Tennis.length > 0 && <div _ngcontent-njs-c64="" className={`game-wrap col3 ${(BasketBetslip === true) ? "close" : "null"}`}>
            <h3 _ngcontent-njs-c64="" onClick={() => { setTennisBetslip(!TennisBetslip) }}>
              <a _ngcontent-njs-c64="" class="to-expand ng-binding" toggle-class="">Tennis</a>
            </h3>
            <ul _ngcontent-njs-c64="" class="sports-header">
              <li _ngcontent-njs-c64="" class="col-game"></li>
              <li _ngcontent-njs-c64="" class="col-matched" style={{ width: '26.4%' }}></li>
              <li _ngcontent-njs-c64="" class="sport-odds">1</li>
              <li _ngcontent-njs-c64="" class="last-col">2</li>
              <li _ngcontent-njs-c64="" class="info-icon"></li>
            </ul>


            {Tennis.map(function (item, id) {

              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }
              var showColor = false;

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showColor = true;
              }

              if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
                item.runner1BackRate1 = '\u00A0';
              }
              if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
                item.runner2BackRate1 = '\u00A0';
              }
              if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
                item.runner2LayRate1 = '\u00A0';
              }
              if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
                item.runner1LayRate1 = '\u00A0';
              }







              return (

                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) &&
                    <dl _ngcontent-njs-c64="" key={id} className={`sportsgame-list-content-rate ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
                      <dt _ngcontent-njs-c64="">
                        {moment() >= moment(item.marketStartTime) &&
                          <img _ngcontent-njs-c64="" id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}
                        {moment() < moment(item.marketStartTime) && <img _ngcontent-njs-c64="" id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}


                        <a _ngcontent-njs-c64="" href={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.runnerName1}<span class="in_play" style={{ marginLeft: '0px' }}>{customScore !== '' ? customScore : 'vs'}</span>{item.runnerName2}</a>

                        {moment() >= moment(item.marketStartTime) &&
                          <span _ngcontent-njs-c64="" id="dateTimeInfo" class="sportsgame-time-list"><span class="in_play">In-Play</span></span>}
                        {moment() < moment(item.marketStartTime) && <span class="sportsgame-time-list ng-binding ">{item.marketStartTime}</span>}

                      </dt>
                      <dd _ngcontent-njs-c67="" id="matched" class="col-matched" style={{ display: 'block', width: '243px' }}></dd>
                      <dd _ngcontent-njs-c64="" id="selectTemp" class="sport-odds">

                        <a _ngcontent-njs-c64="" id="back1_0" class="back-button ng-binding" >{item.runner1BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay1_0" class="lay-button ng-binding" >{item.runner1LayRate1}</a>
                      </dd>
                      {/* <dd _ngcontent-njs-c64="" class="col-draw">
                      <a _ngcontent-njs-c64="" id="btnBack" class="back-button ">--</a>
                      <a _ngcontent-njs-c64="" id="btnLay" class="lay-button ">--</a>
                    </dd> */}
                      <dd _ngcontent-njs-c64="" class="last-col">
                        <a _ngcontent-njs-c64="" id="back2_0" class="back-button ng-binding">{item.runner2BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay2_0" class="lay-button ng-binding" >{item.runner2LayRate1}</a>
                      </dd>
                      <dd _ngcontent-njs-c64="" class="info-icon">
                        <a _ngcontent-njs-c64="" class="add-favourite " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                      </dd>
                    </dl>
                  }
                </React.Fragment>
              )
            })}

          </div>
          }
          {/* Basketball */}
          {basketball.length > 0 && <div _ngcontent-njs-c64="" className={`game-wrap col3 ${(BasketBetslip === true) ? "close" : "null"}`}>
            <h3 _ngcontent-njs-c64="" onClick={() => { setBasketBetslip(!BasketBetslip) }}>
              <a _ngcontent-njs-c64="" class="to-expand ng-binding" toggle-class="">Basketball</a>
            </h3>
            <ul _ngcontent-njs-c64="" class="sports-header">
              <li _ngcontent-njs-c64="" class="col-game"></li>
              <li _ngcontent-njs-c64="" class="col-matched" style={{ width: '26.4%' }}></li>
              <li _ngcontent-njs-c64="" class="sport-odds">1</li>
              <li _ngcontent-njs-c64="" class="last-col">2</li>
              <li _ngcontent-njs-c64="" class="info-icon"></li>
            </ul>


            {basketball.map(function (item, id) {

              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }
              var showColor = false;

              if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
                showColor = true;
              }

              if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
                item.runner1BackRate1 = '\u00A0';
              }
              if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
                item.runner2BackRate1 = '\u00A0';
              }
              if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
                item.runner2LayRate1 = '\u00A0';
              }
              if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
                item.runner1LayRate1 = '\u00A0';
              }







              return (

                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) &&
                    <dl _ngcontent-njs-c64="" key={id} className={`sportsgame-list-content-rate ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>
                      <dt _ngcontent-njs-c64="">
                        {moment() >= moment(item.marketStartTime) &&
                          <img _ngcontent-njs-c64="" id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}
                        {moment() < moment(item.marketStartTime) && <img _ngcontent-njs-c64="" id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}


                        <a _ngcontent-njs-c64="" href={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} class="ng-binding" onClick={() => { props.changeState(true); }}>{item.eventName}</a>

                        {moment() >= moment(item.marketStartTime) &&
                          <span _ngcontent-njs-c64="" id="dateTimeInfo" class="sportsgame-time-list"><span class="in_play">In-Play</span></span>}
                        {moment() < moment(item.marketStartTime) && <span class="sportsgame-time-list ng-binding ">{item.marketStartTime}</span>}

                      </dt>
                      <dd _ngcontent-njs-c67="" id="matched" class="col-matched" style={{ display: 'block', width: '243px' }}></dd>
                      <dd _ngcontent-njs-c64="" id="selectTemp" class="sport-odds">

                        <a _ngcontent-njs-c64="" id="back1_0" class="back-button ng-binding" >{item.runner1BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay1_0" class="lay-button ng-binding" >{item.runner1LayRate1}</a>
                      </dd>
                      {/* <dd _ngcontent-njs-c64="" class="col-draw">
                      <a _ngcontent-njs-c64="" id="btnBack" class="back-button ">--</a>
                      <a _ngcontent-njs-c64="" id="btnLay" class="lay-button ">--</a>
                    </dd> */}
                      <dd _ngcontent-njs-c64="" class="last-col">
                        <a _ngcontent-njs-c64="" id="back2_0" class="back-button ng-binding">{item.runner2BackRate1}</a>
                        <a _ngcontent-njs-c64="" id="lay2_0" class="lay-button ng-binding" >{item.runner2LayRate1}</a>
                      </dd>
                      <dd _ngcontent-njs-c64="" class="info-icon">
                        <a _ngcontent-njs-c64="" class="add-favourite " style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
                      </dd>
                    </dl>
                  }
                </React.Fragment>
              )
            })}
          </div>
          }
        </React.Fragment>}


        {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && basketball.length === 0) && refresh === false && <div id="noDataDiv" class="no-data">
          <p _ngcontent-njs-c64="">There are no events to be displayed.</p>
        </div>}
      </div>



    </React.Fragment >
  )
}
