import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');

const cookies = new Cookies();

export default function BasketballMatches(props) {

  const [match, setMatch] = useState([]);
  const [newrefresh, setnewrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";
  // vvvv


  const openAESexy = (gameI) => {
    if (!props.isLoggedIn) {
      props.checkShowLogin(true);
      return;
    }
    else {
      var utoken = cookies.get('token');
      var user = cookies.get('id');
      var mil = Date.now();

      if (!user || !utoken) {
        return;
      }
      setnewrefresh(true);
      axios.post('https://liveapi247.live/api/gapcasino/game/url', {
        user: user,
        token: utoken,
        platform: 'GPL_DESKTOP',
        lobby_url: window.location.href,
        game_id: gameI

      })
        .then(result => {

          setTimeout(() => { setnewrefresh(false); }, 4000);
          if (result.data.url) {
            // window.open(result.data.url);
            //var newTab=window.open('about:blank');
            window.location.href = result.data.url;

          }


        })
        .catch(e => {

        });

    }
  }
  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api1/BasketballData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);
        if (newrefresh) {
          setnewrefresh(false);
        }


      })


      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));
  }, []);
  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < match.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(match[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }


  }, [match, cricketUpdate]);

  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      console.log('2', list);

    }
  }



  return (

    <React.Fragment>

      {newrefresh === true &&
        <div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
          <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
            <div _ngcontent-njs-c67="" class="loading">
              <div></div>
              <div></div>
            </div>
            <p>Loading...</p>
          </div>
        </div>}



      {newrefresh === false && <div>

        {match.map(function (item, id) {

          var showtv = false;
          var isbookmaker = false;

          var showInplay = false

          var matchTime;
          var today = moment();
          var tommorow = moment().add(1, 'day');

          if (moment(item.marketStartTime).isSame(today, 'day')) {
            matchTime = moment(item.marketStartTime).format('LT');
          }
          else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
            matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
          }
          else {
            matchTime = item.marketStartTime;
          }


          if (tv.includes(item.eventId)) {
            showtv = true;
          };
          if (bookmaker.includes(item.eventId)) {
            isbookmaker = true;

          };


          if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
            showInplay = true;
          }
          return (

            <li _ngcontent-njs-c67="" key={id} id="highlightEvent_29869541" className={`inplay${showInplay ? "-on" : "-off"}`}>
              <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/7522/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>
                <dl>
                  <dt>
                    {showtv && moment(item.marketStartTime) &&
                      <span _ngcontent-njs-c67="" id="streamingIcon" className="game-live" style={{ display: "flex" }}>game-live</span>}
                    {isbookmaker &&
                      <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker ${showInplay ? "in-play" : "no-play"}`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                    <span _ngcontent-njs-c67="" id="sportsBookIcon_2" className="game-sportsbook" style={{ display: "flex" }}>Premium Tennis</span>
                    {moment() >= moment(item.marketStartTime) &&
                      <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">In-play</span>}

                    {moment() < moment(item.marketStartTime) &&
                      <span _ngcontent-njs-c67="" id="dateTimeInfo" className="time">{matchTime}</span>}
                  </dt>
                  <dd _ngcontent-njs-c67="" id="eventName">{item.eventName}</dd>
                </dl>
              </Link>
              <a _ngcontent-njs-c67="" id={MKPINTN + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>

            </li>

          )
        })}
      </div>}
    </React.Fragment>
  )
}
