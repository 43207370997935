import React, { useState, useEffect } from 'react'
import Loading from '../images/sprite/loading40.gif'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios';



export default function Mybets(props) {
	const [select, setselect] = useState(0)
	const [currentBet, setcurrentBet] = useState([])


	useEffect(() => {

		let gameType = '';
		if (select === 0) {
			gameType = 'Exchange'
		} else if (select === 2) {
			gameType = 'premium'
		} else if (select === 1) {
			gameType = "Casino"
		}
		axios.post('https://liveapi247.live/api1/newcurrentBet', {
			id: props.userid,
			token: props.utoken,
			gameType
		})
			.then(result => {
				if (result.status === 200) {
					setcurrentBet(result.data);
				} else {
					setcurrentBet([]);
				}
			})

	}, [props.userid, select]);



	return (


		<React.Fragment>

			<div _ngcontent-njs-c61 class="col-left">
				<div _ngcontent-njs-c61 class="sub_path">
					<div _ngcontent-njs-c61 class="path">

						<ul _ngcontent-njs-c61 >
							<li _ngcontent-njs-c61 class="path-last"><a >My Account</a></li>
						</ul>
					</div>
					<ul _ngcontent-njs-c61 id="sports-path-popup" class="sports-path-popup">
					</ul>
					<ul _ngcontent-njs-c61 class="sports-side-menu-list">
						<li _ngcontent-njs-c61 ><a id="sub_menu_detail" href="/d/dash/myprofile">My Profile</a></li>
						<li _ngcontent-njs-c61 ><a id="sub_menu_summary" href="/d/dash/balance">Balance Overview</a></li>
						<li _ngcontent-njs-c61 ><a id="sub_menu_accountCashStatement_log" href="/d/dash/AccountStatement">Account Statement
						</a></li>
						<li _ngcontent-njs-c61 ><a id="sub_menu_my_bets" href="/d/dash/mybets" class="select">My Bets</a></li>
						<li _ngcontent-njs-c61 ><a id="sub_menu_activity_log" href="/d/dash/activitylog">Activity Log</a></li>
						<li _ngcontent-njs-c61 ><a id="sub_menu_activity_log" onClick={props.handlepassmodel} >Change password</a></li>
					</ul>
				</div>
			</div>
			<div _ngcontent-njs-c61 class="centar-content report">
				<div _ngcontent-njs-c61 id="message" class="message-content success">
					<a _ngcontent-njs-c61 class="close-button">Close</a>
					<p></p>
				</div>
				<h2 _ngcontent-njs-c61 >My Bets</h2>
				<ul _ngcontent-njs-c61 style={{ display: 'flex', width: '55%' }} class="tab col3">
					<li _ngcontent-njs-c61 ><a class="select" href="/d/dash/mybets">Current Bets</a></li>
					<li _ngcontent-njs-c61 ><a href="/d/dash/bethistory">Bets History</a></li>
					<li _ngcontent-njs-c61 ><a href="/d/dash/ProfitLoss">Profit &amp; Loss</a></li>
					
				</ul>
				<ul _ngcontent-njs-c61 class="report-tab-wrap" >
					<li _ngcontent-njs-c61 onClick={() => { setselect(0) }} className={`report-tab ${(select === 0) ? "select" : "null"}`} id="reportType_exchange" data-reporttabtype="0">
						Exchange
					</li>
					<li _ngcontent-njs-c61 onClick={() => { setselect(1) }} className={`report-tab ${(select === 1) ? "select" : "null"}`} id="reportType_sportsBook" data-reporttabtype="2">
						Casino
					</li>
					<li _ngcontent-njs-c61 onClick={() => { setselect(2) }} className={`report-tab ${(select === 2) ? "select" : "null"}`} id="reportType_bookMaker" data-reporttabtype="3">
						Sportsbook
					</li>
					<li _ngcontent-njs-c61 onClick={() => { setselect(3) }} className={`report-tab ${(select === 3) ? "select" : "null"}`} id="reportType_binary" data-reporttabtype="5">
						Binary
					</li>
				</ul>
				<div _ngcontent-njs-c61 class="function-wrap">
					<ul _ngcontent-njs-c61 class="input-list">
						<li _ngcontent-njs-c61 ><label>Bet Status</label></li>
						<li _ngcontent-njs-c61 >
							<select _ngcontent-njs-c61 id="betStatus"><option value="">Matched</option>
								{/*<option value="Unmatched">Unmatched</option>
						<option value="Matched">Matched</option>*/}
							</select>
						</li>
						<li></li>
						{/*
				<li><label>Order By</label></li>
				<li>
					<input id="orderBetPlaced" type="checkbox" checked=""/><label for="orderBetPlaced">Bet placed</label>
					<input id="orderMarket" type="checkbox"/><label for="orderMarket">Market</label>
				</li>*/}
					</ul>
				</div>

				<table _ngcontent-njs-c61 id="Matched_Table" class="table-s">
					<caption>Matched</caption>
					<tbody _ngcontent-njs-c61 ><tr>
						<th _ngcontent-njs-c61 width="9%" class="align-L">Bet ID
						</th>
						<th _ngcontent-njs-c61 width="9%" class="align-L">PL ID
						</th>
						<th _ngcontent-njs-c61 width="" class="align-L">Market
						</th>
						<th _ngcontent-njs-c61 width="12%">Selection
						</th>
						<th _ngcontent-njs-c61 width="4%">Type
						</th>
						<th _ngcontent-njs-c61 width="8%">Bet placed
						</th>

						<th _ngcontent-njs-c61 width="8%">Stake
						</th>
						<th _ngcontent-njs-c61 width="8%">Avg. odds matched
						</th>


					</tr>




						{currentBet.map((item, index) => {
							var matchName;
							var runner;
							var odds;
							var matchtype;

							if (item.eventType == '4') {
								matchName = 'CRICKET';
							} else if (item.eventType == '1') {
								matchName = 'SOCCER';
							}
							else if (item.eventType == '2') {
								matchName = 'TENNIS';
							}
							if (item.betType == 'fancy') {
								runner = item.runnerName;
								odds = item.rate + '/' + parseFloat(item.teamName * 100).toFixed(0);
							}
							else if (item.betType == 'match') {
								odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
								if (item.teamName == 'A') {
									runner = item.runnerName1;
								}
								else if (item.teamName == 'B') {
									runner = item.runnerName2;
								}
								else if (item.teamName == 'T') {
									runner = 'The Draw';
								}
							}


							if (item.type === 'LAGAI') {
								matchtype = 'BACK';
							}
							else if (item.type === 'KHAI') {
								matchtype = 'LAY';
							} else {
								matchtype = item.type;
							}
							if (select == 2) {
								item.type = 'LAGAI'
								odds = parseFloat(parseFloat(item.rate) + 1).toFixed(2);
								item.betType = 'match'
								runner = item.selectionName
							}




							return (
								<tr _ngcontent-njs-c61 key={index} id="matchRow0" style={{ display: 'table-row' }}>
									<td _ngcontent-njs-c61 class="align-L"><a id="betID" href="javascript: void(0);" class="expand-close" onclick="BettingHistoryUtilHandler.toggleTx(0)">{item.id}</a></td>
									<td _ngcontent-njs-c61 class="align-L" id="playerID">{item.clientId}</td>
									<td _ngcontent-njs-c61 id="matchTitle" class="align-L">{matchName}<img class="fromto" src={Transparent} /><strong>{item.eventName}</strong><img class="fromto" src={Transparent} />{item.betType == 'match' ? item.marketName : 'FANCY_BET'}</td>
									<td _ngcontent-njs-c61 id="matchSelection">{runner}</td>
									<td _ngcontent-njs-c61 ><span id="matchType" className={`${item.type === 'LAGAI' || item.type === 'YES' ? "back" : "lay"}`}>{matchtype}</span></td>
									<td _ngcontent-njs-c61 ><span id="betPlaced" class="small-date">{item.betTime}</span></td>

									<td _ngcontent-njs-c61 id="matchStake">{item.amount}</td>
									<td _ngcontent-njs-c61 id="matchAvgOdds">{odds}</td>


								</tr>
							)
						})}



					</tbody>
					{currentBet.length === 0 && <tbody _ngcontent-njs-c61 id="Matched_Content"><tr id="noDataTemplate" style={{ display: 'table-row' }}>
						<td _ngcontent-njs-c61 class="no-data" colspan="9">
							<p _ngcontent-njs-c61 >You have no active bets.</p>
						</td>
					</tr></tbody>}
				</table>


			</div>
		</React.Fragment>

	)
}
