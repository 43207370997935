import React, { useEffect, useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import Loading from "../images/sprite/loading40.gif"
import moment from 'moment'
require('moment-timezone');


export default function Today(props) {


  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([])
  const [basketball, setBasketball] = useState([])
  const [refresh, setrefresh] = useState(true);

  useEffect(() => {
    axios.get(
      `https://liveapi247.live/api1/cricketData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];

        result.data.map((item) => {

          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }

        });

        setcricket(obj);
      })
      .catch(error => console.log('error'));


    axios.get(
      `https://liveapi247.live/api1/tennisData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }

        });




        setTennis(obj);
      })
      .catch(error => console.log('error'));

    axios.get(
      `https://liveapi247.live/api1/soccerData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }
        });



        setSoccer(obj);
        setrefresh(false);
      })
    axios.get(
      `https://liveapi247.live/api1/BasketballData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];

        result.data.map((item) => {
          var str = item.marketStartTime;
          var res = str.slice(11, 16);
          var today = moment();
          var thatDay = moment(item.marketStartTime);

          if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
            obj.push(item);
          }
        });



        setBasketball(obj);
        setrefresh(false);
      })



      .catch(error => console.log('error'));

  }, [])




  return (
    <div>

      {refresh === true &&
        <div id="loading" class="loading-wrap" style={{ top: '100px' }}>
          <ul class="loading">
            <li><img src={Loading} /></li>
            <li>Loading...
            </li>
            <br />
            <li><span id="progress"></span></li>
          </ul>
        </div>}



      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || basketball.length > 0) && refresh === false && <div _ngcontent-njs-c61 id="sportListWrap" class="sport_list-wrap">

        {cricket.length > 0 && <div _ngcontent-njs-c61 class="game-wrap ng-scope">
          <h3 _ngcontent-njs-c61 class="ng-binding">Cricket</h3>


          {cricket.map(function (item, id) {

            var showtoday = false;
            var showFancy = false;




            if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
              showtoday = true;
            }
            if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
              showFancy = true;
            }
            var str = item.marketStartTime;
            var res = str.slice(11, 16);



            var today = moment();
            var thatDay = moment(item.marketStartTime);

            return (
              <React.Fragment>

                {
                  today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')
                  &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id}>

                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>

                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      Cricket
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>T20 Blast*/}
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </a>
                    </dd>
                  </dl>}
              </React.Fragment>
            )
          })}
        </div>}





        {Tennis.length > 0 && <div class="game-wrap ng-scope">
          <h3 _ngcontent-njs-c61 class="ng-binding">Tennis</h3>


          {Tennis.map(function (item, id) {
            var showtoday = false;

            if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
              showtoday = true;
            }

            var str = item.marketStartTime;
            var res = str.slice(11, 16);
            var today = moment();
            var thatDay = moment(item.marketStartTime);


            return (
              <React.Fragment>
                {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id} >
                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>
                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>ATP Vienna 2020*/}
                      Tennis
                      <img class="fromto" src={Transparent} alt="images" />
                      --
                      <img class="fromto" src={Transparent} alt="images" />
                      <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </a>
                    </dd>
                  </dl>}
              </React.Fragment>
            )
          })}

        </div>}





        {Soccer.length > 0 && <div class="game-wrap ng-scope">
          <h3 class="ng-binding">Soccer</h3>



          {Soccer.map(function (item, id) {

            var showtoday = false;

            if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
              showtoday = true;
            }

            var str = item.marketStartTime;
            var res = str.slice(11, 16);
            var today = moment();
            var thatDay = moment(item.marketStartTime);

            return (
              <React.Fragment>
                {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id} >
                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>
                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      Soccer
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>ATP Vienna 2020*/}
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img class="fromto" src={Transparent} alt="images" />
                      <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </a>
                    </dd>
                  </dl>}
              </React.Fragment>
            )
          })}
        </div>}
        {/* basketball */}

        {basketball.length > 0 && <div class="game-wrap ng-scope">
          <h3 class="ng-binding">Basketball</h3>



          {basketball.map(function (item, id) {

            var showtoday = false;

            if (moment().diff(moment(item.marketStartTime), 'seconds') <= 0) {
              showtoday = true;
            }

            var str = item.marketStartTime;
            var res = str.slice(11, 16);
            var today = moment();
            var thatDay = moment(item.marketStartTime);

            return (
              <React.Fragment>
                {today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds') &&
                  <dl _ngcontent-njs-c61 class="sportsgame-list-content ng-scope" key={id} >
                    <dt _ngcontent-njs-c61 id="openTime" class="time ng-binding">{res}</dt>
                    <dd _ngcontent-njs-c61 id="eventDetail" class="ng-binding">
                      Basketball
                      {/*<img class="fromto" src={Transparent} alt="fromto"/>ATP Vienna 2020*/}
                      <img _ngcontent-njs-c61 class="fromto" src={Transparent} alt="images" />
                      --
                      <img class="fromto" src={Transparent} alt="images" />
                      <a _ngcontent-njs-c61 href={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`} id="vsNameClick" onClick={() => { props.changeState(true); }}>
                        <strong _ngcontent-njs-c61 class="ng-binding">{item.eventName}</strong>
                      </a>
                    </dd>
                  </dl>}
              </React.Fragment>
            )
          })}
        </div>}
      </div>}
      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && basketball.length === 0) && refresh === false && <div _ngcontent-njs-c61 id="noDataDiv" class="no-data">
        <p>There are no events to be displayed.</p>
      </div>}


    </div>
  )
}
