import React from 'react';

const PremiumHeader = ({ onClickFancy, sportsId }) => {
    return (
        <>
            <div id="sportsBookHead" className="sportsbook_bet-head">
                <h4 id="betHeader">
                    <span id="sportsBookName" >
                        <pre id="inPlayTag" style={{ display: 'none' }}>in-play</pre>
                        Premium {' '}
                        {sportsId == 4 ? "Cricket" : sportsId == 2 ? "Tennis" : sportsId == 1 ? "Soccer" : ""}
                    </span>
                    <a id="sportsBookRules" className="btn-head_rules">Rules</a>
                </h4>
                {(sportsId == 4) && <a id="showFancyBetBtn" className="other-tab" onClick={onClickFancy}>Fancy Bet</a>}
                <a id="minMaxBtn_1" href="#" className="btn-fancy_info" style={{ display: 'none' }}>Min/Max</a>
                <a id="minMaxBtn_2" href="#" className="btn-fancy_info" style={{}}>Min</a>
                <div id="minMaxWrap_1" className="fancy_info-popup" style={{ display: 'none' }}>
                    <dl>
                        <dt  >Min/Max</dt>
                        <dd id="minMaxInfo_1">0.00</dd>
                    </dl>
                    <dl>
                        <dt id="rebateName_1">Rebate</dt>
                        <dd id="rebateRatio_1">0.00%</dd>
                    </dl>
                    {/*<a onClick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>*/}
                </div>
                <div id="minMaxWrap_2" className="fancy_info-popup" style={{ display: 'none' }}>
                    <dl>
                        <dt>Min</dt>
                        <dd id="minMaxInfo_2">10.00</dd>
                    </dl>
                    <dl>
                        <dt id="rebateName_2" style={{ display: 'none' }}>Rebate</dt>
                        <dd id="rebateRatio_2" style={{ display: 'none' }}>0.00%</dd>
                    </dl>
                    {/*<a onclick="javascript: $j(this).parent().hide();" href="#" className="close">Close</a>*/}
                </div>
            </div>
            <div id="sportsBookTab_2_4" class="special_bets-tab-wrap ps ps--theme_default" >
                <ul class="special_bets-tab">
                    <li><a id="tab_All">All</a></li>
                    <li><a id="tab_Popular">Popular</a></li>
                    <li><a id="tab_Match">Match</a></li>
                    <li><a id="tab_Innings">Innings</a></li>
                    <li><a id="tab_Over">Over</a></li>
                    <li><a id="tab_Batsman">Batsman</a></li>
                    <li><a id="tab_Partnership">Partnership</a></li>
                    <li><a id="tab_Group">Group</a></li>
                    <li><a id="tab_Range">Range</a></li>
                    <li><a id="tab_HeadtoHead">Head-to-Head</a></li>
                    <li><a id="tab_Player">Player</a></li>
                </ul>
                <div class="ps__scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}>
                    <div class="ps__scrollbar-x" tabindex="0" style={{ left: '0px', width: '0px' }}></div></div>
                <div class="ps__scrollbar-y-rail" style={{ top: '0px', right: '0px' }}>
                    <div class="ps__scrollbar-y" tabindex="0" style={{ top: '0px', height: '0px' }}></div></div></div>
        </>
    )
}

export default PremiumHeader;
