import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import axios from 'axios'
import { Link } from "react-router-dom";
import moment from 'moment'
import Loading from "../images/sprite/loading40.gif"

require('moment-timezone');



export default function CricketMatches(props) {
  const [match, setMatch] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [bookmaker, setbookmaker] = useState([])
  const [loading, setloading] = useState(false);

  // vvvv

  const handleRefresh = () => {
    setloading(true);

  }
  useEffect(() => {

    axios.get(
      `https://liveapi247.live/api1/cricketData`)

      .then(result => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {

          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        setMatch(result.data);

      })


      .catch(error => console.log('error'));

    axios.get(
      `https://liveapi247.live/api1/fancy`)

      .then(result => {

        setFancy(result.data);

      })


      .catch(error => console.log('error'));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));
  }, []);



  return (
    <div>

      {loading === true &&
        <div id="oneClickLoading" class="loading-wrap" >
          <ul class="loading">
            <li><img src={Loading} /></li>
            <li>Loading...
            </li>
            <br />
            <li><span id="progress"></span></li>
          </ul>
        </div>}
      {match.map(function (item, id) {



        var showColor = false;
        var showFancy = false;
        var isFancy = false;
        var showbookmaker = false;



        if (item.runner1BackRate1 == null || item.runner1BackRate1 === ' ') {
          item.runner1BackRate1 = '\u00A0';
        }
        if (item.runner1LayRate1 == null || item.runner1LayRate1 === ' ') {
          item.runner1LayRate1 = '\u00A0';
        }
        if (item.runner2BackRate1 == null || item.runner2BackRate1 === ' ') {
          item.runner2BackRate1 = '\u00A0';
        }
        if (item.runner2LayRate1 == null || item.runner2LayRate1 === ' ') {
          item.runner2LayRate1 = '\u00A0';
        }
        if (item.tieBackRate1 == null || item.tieBackRate1 === ' ') {
          item.tieBackRate1 = '--';
        }
        if (item.tieLayRate1 == null || item.tieLayRate1 === ' ') {
          item.tieLayRate1 = "--";
        }

        if (fancy.includes(item.eventId)) {
          isFancy = true;

        };
        if (bookmaker.includes(item.eventId)) {
          showbookmaker = true;

        };


        if (moment().diff(moment(item.marketStartTime), 'minutes') >= -30) {
          showColor = true;

        }

        if (moment().diff(moment(item.marketStartTime), 'seconds') >= 0) {
          showFancy = true;
        }


        var matchTime;
        var today = moment();
        var tommorow = moment().add(1, 'day');

        if (moment(item.marketStartTime).isSame(today, 'day')) {
          matchTime = moment(item.marketStartTime).format('LT');
        }
        else if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
          matchTime = 'Tommorow' + ' ' + moment(item.marketStartTime).format('LT');;
        }
        else {
          matchTime = item.marketStartTime;
        }


        return (

          <dl _ngcontent-lst-c69="" key={id} className={`sportsgame-list-content-rate ${!showColor ? "disable" : ""}`} style={{ cursor: 'pointer' }}>

            <dt _ngcontent-lst-c69="" >
              {moment() >= moment(item.marketStartTime) &&
                <img _ngcontent-lst-c69="" id="playIcon" class="inplay-icon" src={Transparent} alt="playIcon" />}
              {moment() < moment(item.marketStartTime) && <img id="playIcon" class="icon-no_play" src={Transparent} alt="playIcon" />}
              <a _ngcontent-lst-c69="" href={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`} onClick={() => { props.changeState(true); }}>{item.eventName}</a>

              {moment() >= moment(item.marketStartTime) &&
                <span _ngcontent-lst-c69="" id="dateTimeInfo" class="sportsgame-time-list"><span class="in_play">In-Play</span></span>}
              {moment() < moment(item.marketStartTime) && <span class="sportsgame-time-list">{matchTime}</span>}
              {moment() >= moment(item.marketStartTime) &&
                <span class="game-live" id="streamingIcon" style={{ display: 'inline-flex' }}>Live</span>}

              {isFancy &&
                <span _ngcontent-lst-c69="" className="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>

              }
              {showbookmaker &&
                <span class="game-bookmaker in-play" id="bookMakerIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>BookMaker</span>
              }


            </dt>
            <dd _ngcontent-njs-c67="" id="matched" class="col-matched" style={{ display: 'block', width: '89px' }}></dd>
            <dd _ngcontent-lst-c69="" id="selectTemp" class="sport-odds">

              <a _ngcontent-lst-c69="" id="back1_0" class="back-button"    >{item.runner1BackRate1}</a>
              <a _ngcontent-lst-c69="" id="lay1_0" class="lay-button"   >{item.runner1LayRate1}</a>
            </dd>
            <dd _ngcontent-lst-c69="" class="col-draw">
              <a _ngcontent-lst-c69="" id="btnBack" class="back-button"   >{item.tieBackRate1}</a>
              <a _ngcontent-lst-c69="" id="btnLay" class="lay-button"   >{item.tieLayRate1}</a>
            </dd>
            <dd _ngcontent-lst-c69="" class="last-col">
              <a _ngcontent-lst-c69="" id="btnBack" class="back-button" side="Back" selectionid="215817" runner="Atalanta" marketname="Match Odds" inplay="0" markettype="MATCH_ODDS">{item.runner2BackRate1}</a>

              <a _ngcontent-lst-c69="" id="btnLay" class="lay-button" side="Lay" selectionid="215817" runner="Atalanta" marketname="Match Odds" inplay="0" markettype="MATCH_ODDS">{item.runner2LayRate1}</a>

            </dd>
            <dd _ngcontent-lst-c69="" class="info-icon">
              <a _ngcontent-lst-c69="" class="add-favourite" style={{ cursor: ' pointer' }} title="Add to Multi Markets" >.</a>
            </dd>

          </dl>


        )
      })}
    </div>

  )
}


