import React, { useEffect, useState } from "react";
import Transparent from "../images/sprite/transparent.gif";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from 'moment'
import Cookies from 'universal-cookie';
require('moment-timezone');
const cookies = new Cookies();

export default function Inplay(props) {
  const [cricket, setcricket] = useState([]);
  const [Soccer, setSoccer] = useState([]);
  const [Tennis, setTennis] = useState([]);
  const [Basketball, setBasketball] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [tv, settv] = useState([])
  const [bookmaker, setbookmaker] = useState([])
  const [cricketUpdate, setcricketUpdate] = useState(false);
  const [ScoreData, setScoreData] = useState([])

  var MKPIN = "MKPIN", MKPINSC = "MKPINSC", MKPINTN = "MKPINTN";




  useEffect(() => {

    axios
      .get(`https://liveapi247.live/api1/BasketballData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setBasketball(obj);
      })
      .catch((error) => console.log(error));
    axios
      .get(`https://liveapi247.live/api1/tennisData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setTennis(obj);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/soccerData`)

      .then((result) => {
        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })
        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });
        setSoccer(obj);
      })

      .catch((error) => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/cricketData`)

      .then((result) => {

        result.data.map(item => {
          item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
          return item;
        })

        result.data.sort(function (a, b) {
          return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        var obj = [];
        result.data.map((item) => {
          if (moment() >= moment(item.marketStartTime)) {
            obj.push(item);
          }


        });

        setcricket(obj);
      })

      .catch((error) => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checktv`)

      .then(result => {

        settv(result.data);
      })
      .catch(error => console.log(error));

    axios.get(
      `https://liveapi247.live/api1/checkbookmaker`)

      .then(result => {

        setbookmaker(result.data);
      })
      .catch(error => console.log(error));

    axios
      .get(`https://liveapi247.live/api1/fancy`)

      .then((result) => {

        setFancy(result.data);
        setrefresh(false);
      })

      .catch((error) => console.log(error));


    axios.get(
      `https://liveapi247.live/api1/getskyScoreId`)

      .then(result => {

        setScoreData(result.data.data.ScoreID);
      })
      .catch(error => console.log(error));


  }, []);
  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < cricket.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(cricket[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < cricket.length; i++) {

        if (!document.getElementById(MKPIN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(cricket[i].eventId)) {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPIN + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [cricket, cricketUpdate]);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < Soccer.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Soccer[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < Soccer.length; i++) {

        if (!document.getElementById(MKPINSC + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Soccer[i].eventId)) {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINSC + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [Soccer, cricketUpdate]);

  useEffect(() => {
    var list = JSON.parse(localStorage.getItem("multi") || "[]");

    if (props.isLoggedIn == true) {

      for (var i = 0; i < Tennis.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Tennis[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-on"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }
    else {
      for (var i = 0; i < Tennis.length; i++) {

        if (!document.getElementById(MKPINTN + i)) {
          setcricketUpdate(!cricketUpdate);
          return;
        }
        if (list.includes(Tennis[i].eventId)) {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off"
        }
        else {
          var mkpin = document.getElementById(MKPINTN + i);
          mkpin.className = "pin-off";
        }
      }
    }

  }, [Tennis, cricketUpdate]);


  const Multimarketpin = (index, event_id) => {
    if (props.isLoggedIn === false) {
      props.checkShowLogin(true)
    }
    else {
      var list = JSON.parse(localStorage.getItem("multi") || "[]");
      let ctr = 0;
      for (let i = 0; i < list.length; i++) {
        if (list[i] == event_id) {
          let j = i + 1;
          ctr = 1;
          for (; i < list.length; j++, i++) {
            list[i] = list[j];
          }
          list.splice([i - 1]);
        }
      }
      if (ctr == 0) {
        list.push(event_id);
      }
      localStorage.setItem("multi", JSON.stringify(list));
      setcricketUpdate(!cricketUpdate);

      // console.log('2', list);

    }
  }



  return (
    <React.Fragment>

      {refresh === true &&
        <div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
          <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
            <div _ngcontent-njs-c67="" class="loading">
              <div _ngcontent-njs-c67="" ></div>
              <div _ngcontent-njs-c67="" ></div>
            </div>
            <p _ngcontent-njs-c67="" >Loading...</p>
          </div>
        </div>}




      {(cricket.length > 0 || Soccer.length > 0 || Tennis.length > 0 || Basketball.length > 0) && refresh === false && <div _ngcontent-njs-c67="" class="mob-nav-highlight_list" id="inPlayData">

        {cricket.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_4" style={{ display: "block" }}>
          <h3>Cricket</h3>

          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_4">


            <React.Fragment>



            </React.Fragment>

            <React.Fragment>
              {cricket.map(function (item, id) {

                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }
                var showColor = false;
                var showFancy = false;
                var isFancy = false;

                var showtv = false;
                var isbookmaker = false;

                if (fancy.includes(item.eventId)) {
                  isFancy = true;
                }

                if (tv.includes(item.eventId)) {
                  showtv = true;
                };
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;

                };


                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) && (
                      <li _ngcontent-njs-c67=""
                        id="inPlayEvent_30062394"
                        key={id}
                        className={`inplay-on`}
                      >
                        <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/4/${item.eventId}/${item.marketId}`}
                          id="info"
                          onClick={() => {
                            props.changeState(true);
                          }}
                        >
                          <dl _ngcontent-njs-c67="" >
                            <dt _ngcontent-njs-c67="" >
                              {showtv && <span
                                id="streamingIcon"
                                class="game-live"
                                style={{ display: "flex" }}
                              >
                                game-live
                              </span>}
                              {isFancy && (
                                <span _ngcontent-njs-c67=""
                                  id="fancyBetIcon"
                                  className={`game-fancy in-play
                                  `}
                                  style={{ display: "flex" }}
                                >
                                  <pre>in-play</pre>Fancy
                                </span>
                              )}

                              {isbookmaker &&
                                <span id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}
                              <span id="inPlayStatus_30062394" class="time">
                                In-Play
                              </span>
                            </dt>
                            <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                          </dl>
                        </Link>
                        <a _ngcontent-njs-c67="" id={MKPIN + id} className={`pin-off`} onClick={() => Multimarketpin(id, item.eventId)}></a>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>


          </ul>
        </div>}


        {Soccer.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_1" style={{ display: "block" }}>
          <h3 _ngcontent-njs-c67="" >Soccer</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_1">






            <React.Fragment>
              {Soccer.map(function (item, id) {


                let customScore = '';
                let elapsTime = '';
                let filteredData = ScoreData &&
                  ScoreData.filter(x => String(x.eventId) === String(item.eventId));
                // let filteredData = [];
                if (filteredData && filteredData.length > 0) {
                  customScore = filteredData[0].score;
                  elapsTime = filteredData[0].timeElapsed
                }

                var showColor = false;
                var showtv = false;
                var isbookmaker = false;



                if (tv.includes(item.eventId)) {
                  showtv = true;
                }
                if (bookmaker.includes(item.eventId)) {
                  isbookmaker = true;

                }

                return (
                  <React.Fragment key={id}>
                    {moment() >= moment(item.marketStartTime) && (
                      <li _ngcontent-njs-c67=""
                        id="inPlayEvent_-10065371"
                        key={id}
                        className={`inplay-on`}
                      >
                        <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/1/${item.eventId}/${item.marketId}`}
                          id="info"
                          onClick={() => {
                            props.changeState(true);
                          }}
                        >
                          <dl _ngcontent-njs-c67="" >
                            <dt _ngcontent-njs-c67="" >
                              {showtv && <span _ngcontent-njs-c67=""
                                id="streamingIcon"
                                class="game-live"
                                style={{ display: "flex" }}
                              >
                                game-live
                              </span>}

                              {isbookmaker &&
                                <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

                              <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                              {moment() >= moment(item.marketStartTime) && (
                                <span id="inPlayStatus" className="time">
                                  In-Play
                                </span>
                              )}

                            </dt>
                            <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                          </dl>
                        </Link>
                        <a _ngcontent-njs-c67="" id={MKPINSC + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          </ul>
        </div>}


        {Tennis.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_2" style={{ display: "block" }}>
          <h3 _ngcontent-njs-c67="" >Tennis</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_2">




            {Tennis.map(function (item, id) {


              let customScore = '';
              let elapsTime = '';
              let filteredData = ScoreData &&
                ScoreData.filter(x => String(x.eventId) === String(item.eventId));
              // let filteredData = [];
              if (filteredData && filteredData.length > 0) {
                customScore = filteredData[0].score;
                elapsTime = filteredData[0].timeElapsed
              }

              var showColor = false;

              var showtv = false;
              var isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }
              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <li _ngcontent-njs-c67=""
                      id="inPlayEvent_-10065496"
                      key={id}
                      className={`inplay-on`}
                    >
                      <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/2/${item.eventId}/${item.marketId}`}
                        id="info"
                        onClick={() => {
                          props.changeState(true);
                        }}
                      >
                        <dl _ngcontent-njs-c67="" >
                          <dt _ngcontent-njs-c67="" >
                            {showtv && <span id="streamingIcon" class="game-live">
                              game-live
                            </span>}

                            {isbookmaker &&
                              <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

                            <span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            {moment() >= moment(item.marketStartTime) && (
                              <span _ngcontent-njs-c67="" id="inPlayStatus" className="time">
                                In-Play
                              </span>
                            )}


                          </dt>
                          <dd _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                        </dl>
                      </Link>
                      <a _ngcontent-njs-c67="" id={MKPINTN + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div>}

        {/* basketball */}

        {/* {Basketball.length > 0 && <div _ngcontent-njs-c67="" id="inPlayEventType_2" style={{ display: "block" }}>
          <h3 _ngcontent-njs-c67="" >Basketball</h3>
          <ul _ngcontent-njs-c67="" class="mob-sports-highlight-list" id="inPlayList_2">




            {Basketball.map(function (item, id) {

        let customScore = '';
        let elapsTime = '';
        let filteredData = ScoreData && 
 ScoreData.filter(x => String(x.eventId) === String(item.eventId));
        // let filteredData = [];
        if (filteredData && filteredData.length > 0) {
          customScore = filteredData[0].score;
          elapsTime = filteredData[0].timeElapsed
        }


              var showColor = false;

              var showtv = false;
              var isbookmaker = false;

              if (tv.includes(item.eventId)) {
                showtv = true;
              }
              if (bookmaker.includes(item.eventId)) {
                isbookmaker = true;

              }
              return (
                <React.Fragment key={id}>
                  {moment() >= moment(item.marketStartTime) && (
                    <li _ngcontent-njs-c67=""
                      id="inPlayEvent_-10065496"
                      key={id}
                      className={`inplay-on`}
                    >
                      <Link _ngcontent-njs-c67="" to={`/d/dash/fullmarket/7522/${item.eventId}/${item.marketId}`}
                        id="info"
                        onClick={() => {
                          props.changeState(true);
                        }}
                      >
                        <dl _ngcontent-njs-c67="" >
                          <dt _ngcontent-njs-c67="" >
                            {showtv && <span id="streamingIcon" class="game-live">
                              game-live
                            </span>}

                            {isbookmaker &&
                              <span _ngcontent-njs-c67="" id="bookMakerIcon" className={`game-bookmaker in-play`} style={{ display: "flex" }}><pre>in-play</pre>BookMaker</span>}

<span id="sportsBookIcon_2" class="game-sportsbook" style={{ display: "flex" }}>Premium Cricket</span>
                            {moment() >= moment(item.marketStartTime) && (
                              <span _ngcontent-njs-c67="" id="inPlayStatus" className="time">
                                In-Play
                              </span>
                            )}


                          </dt>
                          <dd  _ngcontent-njs-c67="" id="eventName">{item.runnerName1} <span class="in_play">{customScore !== '' ? customScore : 'vs'}</span> {item.runnerName2}</dd>
                        </dl>
                      </Link>
                      <a _ngcontent-njs-c67="" id={MKPINTN + id} class="pin-off" onClick={() => Multimarketpin(id, item.eventId)}></a>
                    </li>
                  )}
                </React.Fragment>
              );
            })}

          </ul>
        </div>} */}

      </div>}


      {(cricket.length === 0 && Soccer.length === 0 && Tennis.length === 0 && Basketball.length === 0) && refresh === false &&
        <div _ngcontent-njs-c67="" id="noMultiMarkets" className="no-data">
          <h3 _ngcontent-njs-c67="" >There are currently no matches in Play.</h3>
          <p _ngcontent-njs-c67="" >Please check upcoming matches.</p>
        </div>
      }
    </React.Fragment>
  );
}
